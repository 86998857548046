<template>
    <section id="Contentservice" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>What we Do</h2>
          <p style="padding-top:20px">We provide you with valuable content strategy for your business, in the form of blog, newsletters or regular updates of content on your website. We understand the value of content reaching out to customers in the search query world of the internet.</p>
        </div>
        <div class="row" style="padding-top:30px">
          <div class="col-lg-3 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4 style="font-size: 16px;">{{ obj.header }}</h4>
              <h6 style="font-size: 14px;line-height: 20px;">{{ obj.subtitle }}</h6>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/Content-sales.jpg',
        header: 'Sales point of view',
        subtitle: 'Content focused on the outbound sales',
        caption: 'It is necessary that relevant content that eventually leads to more amount of traffic is converted to conversions. It can be achieved through sales that focus on the target audiences. The audience way of thinking and their interest is stressed on, by being able to personally connect with the readers.'
      },
      {
        path: 'services/Cost-effectiveness.jpg',
        header: 'Cost-effectiveness',
        subtitle: 'The organic way to drive traffic',
        caption: 'Content marketing is the most cost-effective way to generate more inbound traffic and increase outbound sales. It is because of the close connection between content strategy and sales generating more new content based on the readers’ preferences.'
      },
      {
        path: 'services/Customer-relations.jpg',
        header: 'Customer relations',
        subtitle: 'Customers play an important role',
        caption: 'Customer relations are the crux of marketing. Both the sales team and the content team need to watch the leads requirements and their behaviour to ensure leads convert and both teams can improve and monitor their approaches.'
      },
      {
        path: 'services/Content-Marketing.jpg',
        header: 'Content Strategy',
        subtitle: 'A viable result oriented strategy',
        caption: 'Having a tangible content marketing strategy is an easy proven way to focus on the content itself. This strategy enables businesses to design a content management system, the target audience, focusing on the uniqueness and regular updated content.'
      }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Contentservice {
    padding: 50px 30px 10px 30px;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>