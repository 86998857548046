<template>
    <Header/>
    <Contentheader/>
    <Contentservice/>
    <Footer/>
</template>

<script>

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Contentservice from '@/components/Contentservice.vue'
import Contentheader from '@/components/Contentheader.vue'

export default {
  components: {
     Header,
    Footer,
    Contentservice,
    Contentheader
  }
}
</script>